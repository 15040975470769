import request from '@/utils/request'

// api地址
const api = {
  all: '/rnt/battery',
  save: '/rnt/battery',
  update: '/rnt/battery',
  info: '/rnt/battery/',
  delete: '/rnt/battery/',
  deleteBatch: '/rnt/battery/batch',
  page: '/rnt/battery/page',
  batteryDictdata: '/sys/dictdata',
  enable: '/rnt/battery/enable/',
  dictData: '/sys/dictdata/page'
}

/**
 * 根据id查询
 * @param id id
 * @returns {Promise<Object>}
 */
export function getById(id) {
  return new Promise((resolve, reject) => {
    request.get(api.info + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.put(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
/**
 * 查询列表
 * 分页查询换电池协议字典项
 * @param params 参数
 * @returns {Promise<Array>}
 */
export const batteryDictdata = () => {
  const params = {
    dictCode: 'batteryProtocol'
  }
  return new Promise((resolve, reject) => {
    request.get(api.batteryDictdata, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 查询列表
 * 分页查询字典项
 * @param params 参数
 * @returns {Promise<Array>}
 */
export const dictData = (params) => {
  return new Promise((resolve, reject) => {
    request.get(api.dictData, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 修改电池启用状态
 * @param data
 * @returns {Promise<Array>}
 */
export function enable(data) {
  return new Promise((resolve, reject) => {
    const params = 'enable=' + data.enable
    request.post(api.enable + data.batteryId, params).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
