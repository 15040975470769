<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="730"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改电池' : '新建电池'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 7 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="12" :sm="20" :xs="24">
          <a-form-item label="电池编号:" name="batteryCode">
            <a-input
              v-model:value="form.batteryCode"
              placeholder="请输入电池编号"
              allow-clear
              :disabled="true"
            />
          </a-form-item>          
          <a-form-item label="电池状态:" name="state">
            <a-select
              v-model:value="form.state"
              placeholder="请选择电池状态"
              allow-clear
            >
              <a-select-option
                v-for="item in batteryStateList"
                :key="item.dictDataId"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="仓库名称:" name="warehouseName">
            <a-input
              v-model:value="form.warehouseName"
              placeholder="请输入仓库名称"
              allow-clear
            />
          </a-form-item>
          <!-- <a-form-item label="电池图片:" name="pointPhoto">
            <a-upload
              list-type="picture-card"
              v-model:file-list="pointPhotoList"
              @preview="handlePreview"
              :customRequest="({ file }) => uploadFile(file, 'pointPhoto')"
              :remove="removeFile"
            >
              <div v-if="pointPhotoList.length < 1">
                <plus-outlined />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item> -->
        </a-col>
        <a-col :md="12" :sm="20" :xs="24">
          <a-form-item label="运营商名称:" name="operatorId">
            <a-select
              v-model:value="form.operatorId"
              placeholder="请选择运营商名称"
              @change="changeOperator"
              allow-clear
            >
              <a-select-option
                v-for="item in operatorList"
                :key="item.operatorId"
                :value="item.operatorId"
              >
                {{ item.operatorName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="所属租赁点:" name="pointId">
            <a-select
              v-model:value="form.pointId"
              placeholder="请选择所属租赁点"
              allow-clear
            >
              <a-select-option
                v-for="item in pointList"
                :key="item.pointId"
                :value="item.pointId"
              >
                {{ item.pointName }}
              </a-select-option>
            </a-select>
          </a-form-item>          
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as batteryApi from '@/api/rnt/battery'
import * as batteryModelApi from '@/api/rnt/batteryModel'
import * as operatorApi from '@/api/rnt/operator'
import * as pointListApi from '@/api/rnt/point'
import setting from '@/config/setting'
import * as userApi from '@/api/sys/user'
import * as dictDataApi from '@/api/dictData/index'
// import { PlusOutlined } from '@ant-design/icons-vue'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'batteryEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    // PlusOutlined
  },
  data() {
    return {
      tags: [],
      batteryStateList: [],
      inputVisible: false,
      inputValue: '',
      uploadUrl: setting.uploadUrl,
      // 表单数据
      form: Object.assign({}, this.data, {
        modulesArray: [],
        indeterminate: false,
        checkAll: false
      }),
      // 编辑弹窗表单验证规则
      rules: {
        batteryCode: [
          {
            required: true,
            message: '请输入电池编号',
            type: 'string',
            trigger: 'blur'
          }
        ],
        operatorId: [
          {
            required: true,
            message: '请输入运营商名称',
            type: 'number',
            trigger: 'blur'
          }
        ],
        brand: [
          {
            required: true,
            message: '请输入电池品牌',
            type: 'string',
            trigger: 'blur'
          }
        ],
        deviceCode: [
          {
            required: true,
            message: '请输入设备编号',
            type: 'string',
            trigger: 'blur'
          }
        ],
        model: [
          {
            required: true,
            message: '请输入型号',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 用户列表
      userList: [],
      // 项目列表
      modules: [],
      // 电池品牌
      brandList: [],
      // 型号
      modelList: [],
      // 运营商名称
      operatorList: [],
      // 电池协议
      protocolList: [],
      // 所属租赁点
      pointList: [],
      // 电池图片
      pointPhotoList: [],
      previewImage: '',
      previewVisible: false
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        this.form.modulesArray =
          !!this.data.modules === true ? this.data.modules.split(',') : []
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
      this.queryBatteryBrand()
      this.queryBatteryModel()
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            'selectedRows: ',
            selectedRows
          )
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name
          }
        })
      }
    }
  },
  mounted() {
    this.queryOperatorList()
    this.getbatteryDictdata()
    this.queryPointList()
    this.queryBatteryBrand()
    this.queryBatteryModel()
    this.getBatteryDictdata()
  },
  methods: {
    // 表格
    onChange(value) {
      console.log(value)
    },
    // removeFile(file) {
    //   this.$http
    //     .delete('/file/remove', {
    //       path: file.url
    //     })
    //     .then((res) => {
    //       if (res.data.code === 0) {
    //         if (file.uid === '-4') {
    //           this.pointPhotoList = []
    //           this.form.pointPhoto = ''
    //         }
    //         this.$message.success(res.data.msg)
    //       } else {
    //         this.$message.error(res.data.msg)
    //       }
    //     })
    //     .catch((err) => {
    //       this.$message.error(err)
    //     })
    // },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          if (this.form.modulesArray) {
            this.form.modules = this.form.modulesArray.join(',')
          }
          console.log('form', this.form)
          batteryApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },

    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 获取电池业务状态
    getBatteryDictdata() {
      dictDataApi
        .getDictData({
          dictCode: 'batteryState'
        })
        .then((res) => {
          if (res.code === 0) {
            this.batteryStateList = res.data
          } else {
            this.$error(res.msg)
          }
        })
        .catch((e) => {
          this.$error(e.message)
        })
    },
    // 初始化电池品牌list
    queryBatteryBrand() {
      if (this.form.operatorId) {
        batteryModelApi
          .brand({ operatorId: this.form.operatorId })
          .then((res) => {
            if (res.code === 0) {
              this.brandList = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      } else {
        batteryModelApi
          .brand()
          .then((res) => {
            if (res.code === 0) {
              this.brandList = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      }
    },
    // 初始化电池型号list
    queryBatteryModel() {
      if (this.form.brand && this.form.operatorId) {
        batteryModelApi
          .model({
            operatorId: this.form.operatorId,
            brandName: this.form.brand
          })
          .then((res) => {
            if (res.code === 0) {
              this.modelList = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      } else {
        batteryModelApi
          .model()
          .then((res) => {
            if (res.code === 0) {
              this.modelList = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      }
    },
    /* 查询所属租赁点pointList */
    queryPointList() {
      pointListApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询用户列表 */
    queryUsers() {
      userApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.userList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询用户列表 */
    queryProjects() {
      batteryApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.projectList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询运营商 */
    queryOperatorList() {
      operatorApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 运营商改变获取对应品牌列表
    changeOperator(value) {
      this.form.brand = null
      this.form.model = null
      batteryModelApi
        .brand({ operatorId: value })
        .then((res) => {
          if (res.code === 0) {
            this.brandList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 电池品牌改变获取对应型号
    changeBatteryBrand(value) {
      this.form.model = null
      batteryModelApi
        .model({ brandName: value, operatorId: this.form.operatorId })
        .then((res) => {
          if (res.code === 0) {
            this.modelList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询电池协议
    getbatteryDictdata() {
      batteryApi
        .batteryDictdata()
        .then((res) => {
          if (res.code === 0) {
            this.protocolList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    checkAllChange(e) {
      Object.assign(this.form, {
        modulesArray: e.target.checked ? this.moduleOptions : [],
        indeterminate: false
      })
    },
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    handleChange({ fileList }) {
      this.fileList = fileList
    }
    // uploadFile(file, name) {
    //   const formData = new FormData()
    //   formData.append('file', file)
    //   const hide = this.$message.loading('上传中..', 0)
    //   this.$http
    //     .post(this.uploadUrl, formData)
    //     .then((res) => {
    //       hide()
    //       if (res.data.code === 0) {
    //         if (name === 'pointPhoto') {
    //           this.pointPhotoList[0] = {
    //             uid: '-4',
    //             name: 'image.png',
    //             status: 'done',
    //             url: res.data.location
    //           }
    //         }
    //         this.form[name] = res.data.location
    //       } else {
    //         this.$message.error(res.data.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       hide()
    //       this.$message.error(e.message)
    //     })
    //   return false
    // }
  }
}
</script>

<style scoped>
</style>
