import request from '@/utils/request'

// api地址
const api = {
  all: '/sys/user'
}

/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, { params: params }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
